import { Component, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ContextService } from 'src/app/services/application/context.service';

import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { ClientAdditionInformationService } from 'src/app/services/business/client-additional-information';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticateService } from './services/authenticate.service';
import { UserType } from 'src/app/models/user-type.enum';
import { UserAuthenticated } from './models/user-authenticated.model';
import { FirstAccessService } from 'src/app/components/user/services/first-access.service';
import { FirstAccessContact } from 'src/app/components/user/models/first-access-contact.model';
import { Guid } from 'guid-typescript';
import { Router } from '@angular/router';

declare var $: any;
declare var grecaptcha: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  public appVersion: string;
  public loginCpf = UserType.CPF;
  public loginCnpj = UserType.CNPJ;
  public captchatoken: string = null;
  public redirectToUrl: string = null;
  public emailRecoveryPassword: string;

  private _id: Guid;
  private _attempt = false;
  private _tabSelected = UserType.CPF;

  form: FormGroup;
  formModal: FormGroup;

  get contactClient(): string {
    if (this.context.authService.currentScope) {
      return this.context.authService.currentScope.scopeContact;
    }
    return '';
  }

  get showLoginScopeLogo(): boolean {
    if (this.context.authService.currentScope) {
      return this.context.authService.currentScope.showLoginScopeLogo;
    }
    return true;
  }

  get scopeLogo(): string {
    if (this.context.authService.currentScope) {
      return this.context.authService.currentScope.brandImageUrl;
    }
    return '';
  }

  get scopeName(): string {
    if (this.context.authService.currentScope) {
      return this.context.authService.currentScope.name;
    }
    return '';
  }

  get scopeHomePageImageUrl(): string {
    if (this.context.authService.currentScope) {
      return this.context.authService.currentScope.homePageImageUrl;
    }
    return '';
  }

  get loginPageWelcomeText(): string {
    if (this.context.authService.currentScope) {
      return this.context.authService.currentScope.loginPageWelcomeText;
    }
    return '';
  }

  get tabSelected(): UserType {
    return this._tabSelected;
  }

  set tabSelected(newTab: UserType) {
    this._tabSelected = newTab;
  }

  get attempt(): boolean {
    return this._attempt;
  }

  get cpf(): AbstractControl { return this.form.get('cpf'); }
  get cnpj(): AbstractControl { return this.form.get('cnpj'); }
  get userType(): AbstractControl { return this.form.get('userType'); }
  get passwordCpf(): AbstractControl { return this.form.get('passwordCpf'); }
  get passwordCnpj(): AbstractControl { return this.form.get('passwordCnpj'); }


  get email(): AbstractControl { return this.formModal.get('email'); }
  get phone(): AbstractControl { return this.formModal.get('phone'); }

  constructor(
    private context: ContextService,
    private messageService: MessageSwalService,
    private clientInformationService: ClientAdditionInformationService,
    private fb: FormBuilder,
    private authenticateService: AuthenticateService,
    private firstAccessService: FirstAccessService,
    private cdRef: ChangeDetectorRef,
    private router: Router) {

    $('.tooltip').remove();
    this.context.authService.logoff();
    this.appVersion = environment.version;

    this.initializeForm();
    this.changeTypeLogin(UserType.CPF);
  }

  ngOnInit() {
    this._attempt = false;
  }

  ngAfterViewInit(): void {
    $('.grecaptcha-badge').addClass('shows');
    this.clientInformationService.loadScope(() => this.cdRef.detectChanges());
    this.startCaptcha();
  }

  startCaptcha() {
    const self = this;
    grecaptcha.ready(() => {
      grecaptcha.execute('6LdwHssUAAAAAI8dkcZnJ0Wa7SFPi2OjHTjJKqHD', { action: 'Login' }).then((token: any) => {
        self.captchatoken = token;
      });
    });
  }

  changeTypeLogin(tab: UserType): void {
    this._attempt = false;
    this.clearValidationsForm();
    this.setValidationsForm(tab);
    this.userType.setValue(tab);
  }

  public authenticate() {
    this._attempt = true;
    if (this.form.invalid) {
      return;
    }

    if (this.tabSelected === 1) {
      this.authenticateService.authenticateByCpf(
        this.context.authService.currentScope ? this.context.authService.currentScope.key : '',
        this.cpf.value,
        this.passwordCpf.value,
        this.captchatoken
      ).subscribe(response => this.success(response),
        error => {
          this.form.controls['cpf'].setErrors({ 'incorrect': true });
          this.form.controls['passwordCpf'].setErrors({ 'incorrect': true });
          this.clearValidationsForm();
          this.error(error);
        });

      return;
    }
    else {
      this.authenticateService.authenticateByCnpj(
        this.context.authService.currentScope.key,
        this.cnpj.value,
        this.passwordCnpj.value,
        this.captchatoken
      ).subscribe(response => this.success(response),
        error => {
          this.form.controls['cnpj'].setErrors({ 'incorrect': true });
          this.form.controls['passwordCnpj'].setErrors({ 'incorrect': true });
          this.clearValidationsForm();
          this.error(error);
        });
    }
  }

  checkValidity(control: AbstractControl) {
    return this._attempt && control.valid;
  }

  checkInvalidity(control: AbstractControl) {
    return this._attempt && control.invalid;
  }

  update() {
    if (this.formModal.invalid) {
      return;
    }
    const model = new FirstAccessContact(
      this.context.authService.currentScope.key,
      '',
      this._id,
      this.email.value,
      this.phone.value
    );

    this.firstAccessService.updateContact(model)
      .subscribe(response => {
        $('#modal-first-access').modal('hide');
        this.context.navigation.changeRoute('/main/index');
      },
        error => this.error(error));
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      userType: [this.tabSelected, []],
      cpf: ['', []],
      cnpj: ['', []],
      passwordCpf: ['', []],
      passwordCnpj: ['', []]
    });

    this.formModal = this.fb.group({
      email: ['', []],
      phone: ['', []]
    });
  }

  private clearValidationsForm(): void {
    // tslint:disable-next-line: forin
    for (const key in this.form.controls) {
      this.form.get(key).clearValidators();
      this.form.get(key).updateValueAndValidity();
    }
  }

  private setValidationsForm(tabSelected: UserType): void {
    this.tabSelected = tabSelected;
    this.userType.setValidators([Validators.required]);
    if (tabSelected === UserType.CPF) {
      this.passwordCpf.setValidators([Validators.required]);
      this.cpf.setValidators([Validators.required]);
    } else {
      this.passwordCnpj.setValidators([Validators.required]);
      this.cnpj.setValidators([Validators.required]);
    }
  }

  private success(response: UserAuthenticated): void {
    this._id = response.id;
    this.context.authService.initSession(
      response.authToken,
      (new Date(response.expiresAt)),
      response.id.toString(),
      response.name,
      response.email,
      response.privilege,
      response.role,
      false);
    if (response.firstAccess) {
      this.email.setValue(response.email || '');
      this.phone.setValue(response.phone || '');
      $('#modal-first-access').modal('show');
    } else {
      this.context.navigation.changeRoute('/main/my-documents');
    }
  }

  private error(error: string): void {
    this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
  }

}
