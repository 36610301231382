import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserAuthenticated } from 'src/app/pages/security/login/models/user-authenticated.model';
import { AuthenticateService } from 'src/app/pages/security/login/services/authenticate.service';
import { AuthService } from 'src/app/services/application/auth.service';
import { ContextService } from 'src/app/services/application/context.service';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { isNullOrUndefined } from 'util';
import { FirstAccessContact } from '../../../models/first-access-contact.model';
import { NewPassword } from '../../../models/new-password.model';
import { FirstAccessService } from '../../../services/first-access.service';
import { NavigationService } from '../../../services/navigation.service';
import { UserPasswordService } from '../../../services/user-password.service';

declare var $: any;

@Component({
  selector: 'app-first-access-password',
  templateUrl: './first-access-password.component.html',
  styleUrls: ['./first-access-password.component.scss']
})
export class FirstAccessPasswordComponent implements OnInit {

  formModal: FormGroup;

  private _attempt = false;
  private requesting = false;

  constructor(
    private userPasswordService: UserPasswordService,
    private firstAccessService: FirstAccessService,
    public navigationService: NavigationService,
    private messageService: MessageSwalService,
    private authService: AuthService,
    private context: ContextService,
    private fb: FormBuilder) {
    this.formModal = this.fb.group({
      email: ['', []],
      phone: ['', []]
    });
  }

  get attempt(): boolean {
    return this._attempt;
  }

  get cpf(): AbstractControl { return this.navigationService.form.get('cpf'); }
  get cnpj(): AbstractControl { return this.navigationService.form.get('cnpj'); }
  get userType(): AbstractControl { return this.navigationService.form.get('userType'); }
  get password(): AbstractControl { return this.navigationService.form.get('password'); }
  get passwordConfirm(): AbstractControl { return this.navigationService.form.get('passwordConfirm'); }

  get email(): AbstractControl { return this.formModal.get('email'); }
  get phone(): AbstractControl { return this.formModal.get('phone'); }

  get isRequesting() {
    return this.requesting;
  }

  ngOnInit() {
    this.navigationService.clearValidationsForm();
    this.setValidationsForm();
  }

  async save(): Promise<void> {
    this._attempt = true;
    if (this.navigationService.form.invalid) {
      return;
    }
    this.requesting = true;
    const model = new NewPassword(
      this.context.authService.currentScope.key,
      '',
      this.navigationService.userId,
      this.password.value,
      this.passwordConfirm.value,
      this.navigationService.form.get('token').value);

    await this.userPasswordService.saveNewPassword(model)
      .toPromise()
      .then(response => {
        this.success(response);
        this.requesting = false;
      })
      .catch(error => {
        this.error(error);
        this.requesting = false;
      });
  }

  checkValidity(control: AbstractControl) {
    return this._attempt && control.valid;
  }

  checkInvalidity(control: AbstractControl) {
    return this._attempt && control.invalid;
  }

  private error(error: string): void {
    this.messageService.displayMessage({ warninglevel: 1, message: error }, () => { });
  }

  private setValidationsForm(): void {
    this.password.setValidators([Validators.required, Validators.minLength(8)]);
    this.passwordConfirm.setValidators([Validators.required, Validators.minLength(8)]);
  }

  update() {
    if (this.formModal.invalid) {
      return;
    }

    const model = new FirstAccessContact(
      this.context.authService.currentScope.key,
      '',
      this.navigationService.userId,
      this.email.value,
      this.phone.value
    );
    const documentUser = this.cpf.value !== '' ? this.cpf.value : this.cnpj.value;
    this.authService.setUserDocument = documentUser;

    this.firstAccessService.updateContact(model)
      .subscribe(response => {
        $('#modal-first-access').modal('hide');
        this.context.navigation.changeRoute('/main/my-documents');
        this.requesting = false;
      },
        error => {
          this.error(error);
          this.requesting = false;
        });
  }

  private success(response: UserAuthenticated): void {
    const documentUser = this.cpf.value !== '' ? this.cpf.value : this.cnpj.value;
    this.authService.setUserDocument = documentUser;

    this.context.authService.initSession(
      response.authToken,
      (new Date(response.expiresAt)),
      response.id.toString(),
      response.name,
      response.email,
      response.privilege,
      response.role,
      false);
    if (response.firstAccess) {
      this.email.setValue(response.email || '');
      this.phone.setValue(response.phone || '');
      $('#modal-first-access').modal('show');
    } else {
      this.context.navigation.changeRoute('/main/my-documents');
    }
  }
}
