import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Storage } from 'src/app/framework/storage';
import { ContextService } from 'src/app/services/application/context.service';
import { SecurityService } from 'src/app/services/business/security.service';

@Component({
  selector: 'app-middle',
  templateUrl: './middle.component.html',
  styleUrls: ['./middle.component.scss']
})
export class MiddleComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private context: ContextService,
    private securityService: SecurityService
  ) { }

  ngOnInit() {
    decodeURIComponent(window.location.href);

    this.route.queryParams.subscribe((param) => {
      const user = JSON.parse(param["json_data"]);
      //user.tokenExpirationDate = '2021-09-18T15:23:45.867'

      Storage.setString("scopeKey", user.scope);
      
      this.context.authService.initSession(
        user.token,
        new Date(user.tokenExpirationDate),
        user.userId,
        user.userName,
        user.userEmail,
        user.permission,
        user.role,
        true
      );
      
      this.context.authService.setUserDocument = user.document;

      this.securityService.getScopeByKey(user.scope)
            .subscribe((scope) => {
                this.context.authService.currentScope = scope; 
            });
      setTimeout(() => {
        this.context.isNotSimulate = false;
        this.context.navigation.changeRoute('/main/my-documents');        
      }, 1000);
    });
  }

}
